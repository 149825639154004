<template>
    <div>Actions JJK Works!</div>
</template>
<script lang="ts">

export default {
  name: 'extensionActions',
  components: { }
}

</script>